var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1 table",attrs:{"headers":_vm.headers,"items":_vm.userData,"loading":_vm.loading,"rows-per-page-items":[
      20,
      50,
      { text: '$vuetify.dataIterator.rowsPerPageAll', value: -1 } ]},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-progress-linear',{staticStyle:{"width":"100%"},attrs:{"color":"primary","indeterminate":""}})]},proxy:true},{key:"items",fn:function(ref){
    var user = ref.item;
return [_c('tr',{staticClass:"campaign-user"},[_c('td',[_vm._v(_vm._s(user.NAME))]),_c('td',{staticStyle:{"min-width":"250px"}},[_vm._v(_vm._s(user.COMPANY_NAME))]),_c('td',[_vm._v(_vm._s(user.PHONE_NUMBER))]),_c('td',[_vm._v(_vm._s(user.EMAIL))]),_c('td',{staticClass:"px-2 actions",class:{ loading: _vm.loading }},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mx-1 pointer",attrs:{"color":"grey darken-1"},on:{"click":function($event){return _vm.signInToPlatformWithUser(user)}}},on),[_vm._v(" person ")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t('sign_in_with_user'))+" ")])],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }