import { FirebaseAppFirestore } from '@/app/shared/firebase/firebase-app';
import firebaseNames from '@/app/shared/utilities/firebase-names';
import { AuthorizedUser } from '@/app/shared/utilities/static-types';

export const getAdvertiserUserList = async () => {
  const data = await FirebaseAppFirestore
    .collection(firebaseNames.USERS_INFO)
    .where('ROLES', 'array-contains', { VAL: 'advertiser', DISPLAY_NAME: 'Advertiser' })
    .get();
  return data.docs.map((querySnapshot) => querySnapshot.data() as AuthorizedUser);

};
