














import { Component, Vue } from 'vue-property-decorator';
import { getAdvertiserUserList } from '@adminAdvertisers/screens/AdvertiserUserList/actions';
import { AuthorizedUser } from '@/app/shared/utilities/static-types';
import UserListTable from '@adminAdvertisers/screens/AdvertiserUserList/components/UserListTable.vue';
import { signInToPlatformWithUserAsAdmin } from '@/app/shared/utilities/helper-functions';

@Component({
  components: {
    UserListTable,
  },
  data() {
    return {
      headers: [
        {
          text: this.$t('name').toString(),
          value: 'NAME',
        },
        {
          text: this.$t('company_name').toString(),
          value: 'COMPANY_NAME',
        }, {
          text: this.$t('phone_no').toString(),
          value: 'PHONE_NUMBER',
          sortable: false,
        }, {
          text: this.$t('email').toString(),
          value: 'email',
          sortable: false,
        }, {
          text: this.$t('actions').toString(),
          value: 'ACTIONS',
          sortable: false,
        },
      ],
    };
  },
})
export default class AdvertiserUserList extends Vue {
  public loading: boolean = false;
  public usersList: AuthorizedUser[] = [];

  public async signInWithAdvertiserAccount(user: AuthorizedUser) {
    this.loading = true;
    try {
      await signInToPlatformWithUserAsAdmin(user);
    } finally {
      this.loading = false;
    }
  }

  public async created() {
    this.loading = true;
    try {
      this.usersList = await getAdvertiserUserList();
    } finally {
      this.loading = false;
    }
  }
}
