















































import { Component, Vue, Prop } from 'vue-property-decorator';
import { AuthorizedUser, Callback, DataTableHeaders } from '@/app/shared/utilities/static-types';

@Component({})
export default class UserListTable extends Vue {
  @Prop({ required: true }) public headers!: DataTableHeaders[];
  @Prop({ required: true }) public userData!: AuthorizedUser[];
  @Prop({ required: true }) public loading!: boolean;
  @Prop({ required: true }) public signInToPlatformWithUser!: Callback<AuthorizedUser, void>;
}
